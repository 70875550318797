@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,*::after,*::before{
  /* outline: 2px solid red */
}

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  ul,
  ol {
    list-style: revert;
  }
}
iframe {
  position: unset !important;
  z-index: -4 !important;
}
.notes-editor > * {
  border: none !important;
}
.ql-toolbar {
  --tw-shadow: 0px 8px 24px rgba(112, 114, 176, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ql-editor {
  padding: 8px !important;
}

.weekRows {
  grid-template-rows: (96, minmax(0, 1fr));
}

.carousel-primary {
  display: flex;
  justify-content: flex-start;
  animation: scroll-horizontal 40s linear infinite;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.carousel-primary:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* .scroll-container:hover > .carousel-primary:not(:hover) {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
} */

.Toastify__toast-theme--dark {
  background-color: #212529 !important;
  color: white !important;
}
.Toastify__progress-bar-theme--dark {
  background-color: #ffcf00 !important;
}

.Toastify__toast-container {
  top: 10em !important;
}
