.swiper-pagination {
  top: 85%;
}

.swiper-pagination-bullet{
  background: white;
  opacity: 0.5;
}


.swiper-pagination-bullet-active {
  background: #ffffff;
  opacity: 1;
}
